/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { styled as styledMUI } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { babyPortalApi } from 'data/axios';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { endpoints } from 'services/api/endpoints';
import {
  CheckFreeTrialSubscription,
  GetSubscriptionDetails,
  SubscriptionType
} from 'services/api/subscriptionApi';
import { useAppDispatch } from 'store/hooks';
import { fetchAllSubscription } from 'store/reducers/subscriptionReducer';
import styled from 'styled-components';

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
`;

// const BottomContainer = styledMUI('div')(({ theme }) => ({
//   width: '100%',
//   marginBottom: '90px',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   [theme.breakpoints.up('sm')]: {
//     justifyContent: 'flex-end'
//   }
// }));

const Heading = styled.div`
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.01em;
  text-align: left;
`;
const SubHeading = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #484c4f;
`;

const FormContainer = styledMUI('form')(({ theme }) => ({
  height: '90%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.up('sm')]: {
    maxWidth: '320px',
    margin: '0 auto',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '6px'
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '360px'
  }
}));

const CenteredContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface DeviceInfo {
  device_id: string;
  password: string;
  infant_name: string;
  image_url: string;
  sku_id: string;
  is_paired: boolean;
  is_activated: boolean;
}

function SetupInit() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const deviceId = query.get('device_id') || '';
  const [subscriptionData, setSubscriptionData] = useState<SubscriptionType[]>(
    []
  );

  const [isEligibleForTrial, setIsEligibleForTrial] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  console.log('subscriptionData', subscriptionData);
  const dispatch = useAppDispatch();

  const getSubscriptionForRepairedCamera = useCallback(() => {
    return subscriptionData.find((subscriptionItem) => {
      return (
        !subscriptionItem.devices.length &&
        subscriptionItem.subscribe_status === 'Active'
      );
    });
  }, [subscriptionData]);

  const addCameraToSubscription = useCallback(
    async (extSubscription: SubscriptionType) => {
      const payload = {
        device_ids: [deviceId]
      };

      try {
        const subscriptionId = extSubscription.purchase_id;

        const res = await babyPortalApi.post(
          `${endpoints.subscriptionUrl}/${subscriptionId}/devices`,
          payload
        );
        console.log('addCameraToSubscription', res);

        dispatch(fetchAllSubscription());

        navigate('/payment-success', {
          state: {
            isFreeTrial: isEligibleForTrial,
            isUpgrade: false,
            errorMessage: '',
            showSetupComplete: Boolean(deviceId),
            deviceId,
            purchaseId: subscriptionId
          }
        });
      } catch (err) {
        console.log('addCameraToSubscription', err);
      }
    },
    [deviceId, dispatch, isEligibleForTrial, navigate]
  );

  const sendUserToPurchaseSubscription = useCallback(() => {
    navigate('/why-subscribe', {
      replace: true,
      state: {
        isFreeTrial: isEligibleForTrial,
        isUpgrade: false,
        deviceId,
        pairingFlow: true
      }
    });
  }, [deviceId, isEligibleForTrial, navigate]);

  const checkSubscriptionStatus = useCallback(async () => {
    try {
      setIsSubmitting(true);
      // if no subscriptions present means its a fresh setup
      if (subscriptionData?.length === 0) {
        // debugger;
        sendUserToPurchaseSubscription();
        return;
      }

      // if subscriptions are there, it means its either a additional camera or repair an existing camera
      if (subscriptionData.length > 0) {
        // debugger;
        // iterate through subscriptions and check if any existing active subscription exists for repaired camera
        const existingSubscriptionForRepairedCamera =
          getSubscriptionForRepairedCamera();
        if (existingSubscriptionForRepairedCamera) {
          addCameraToSubscription(existingSubscriptionForRepairedCamera);
          return;
        }

        sendUserToPurchaseSubscription();
      }
    } catch (err) {
      console.log(err);
    }
  }, [
    addCameraToSubscription,
    getSubscriptionForRepairedCamera,
    sendUserToPurchaseSubscription,
    subscriptionData.length
  ]);

  const fetchSubscriptionInfo = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await GetSubscriptionDetails();
      setSubscriptionData(response.data.subscriptions);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      checkSubscriptionStatus();
    }
  }, [checkSubscriptionStatus]);

  const authenticateDevice = useCallback(async () => {
    try {
      const result = await babyPortalApi.patch(
        endpoints.updateDevices(deviceId),
        {
          is_activated: true
        }
      );
      console.log('authenticateDevice', result);
    } catch (error) {
      console.log('authenticateDevice error', error);
    }
  }, [deviceId]);

  useEffect(() => {
    authenticateDevice();
    fetchSubscriptionInfo();

    CheckFreeTrialSubscription()
      .then((res) => {
        setIsEligibleForTrial(res.data.trial);
      })
      .catch((error) => {
        console.log('Free trial api call failed: ', error);
      });
  }, [authenticateDevice, fetchSubscriptionInfo]);

  return isLoading ? (
    <CenteredContainer>
      <CircularProgress />
    </CenteredContainer>
  ) : (
    <FormContainer>
      <TopContainer>
        <Heading>
          Congratulations on setting up your camera! You are just a few steps
          from finishing the pairing process.
        </Heading>
        <SubHeading>
          Please click continue to finish the pairing process.
        </SubHeading>
      </TopContainer>
      {isSubmitting && <CircularProgress />}
      {/* <BottomContainer>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{
            outline: 'none',
            textTransform: 'none',
            '&:focus': {
              outline: 'none'
            }
          }}
          style={{
            color: '#fff',
            height: '52px',
            width: '100%',
            maxWidth: '300px',
            borderRadius: '44px',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '24px'
          }}
          disabled={isSubmitting}
        >
          Continue
        </Button>
      </BottomContainer> */}
    </FormContainer>
  );
}
export default SetupInit;
